<script setup>
import {Modal} from 'ant-design-vue';
import {CashierUsdtStore} from "./cashier-usdt-store";
import Button from "~/components/button/Button";
import LangText from "~/components/lang/LangText";

const cashierUsdtStore = CashierUsdtStore();

</script>

<template>
  <Modal
      v-model:open="cashierUsdtStore.feeInfoModalVisible"
      :closable="false"
  >
    <template #footer>
      <Button
          class="w-[100%]"
          @click="cashierUsdtStore.feeInfoModalVisible=false"
      >
        <LangText
            customClass="justify-center"
            msg-key="qCir"
        />
      </Button>
    </template>

    <div>
      <img
          class="w-[56px] h-[56px]"
          src="~/assets/cashier/icon-credit-card-big.png"
      >
      <div class="mt-[24px]">
        <div class="text-gray-900">
          <LangText msg-key="vXyt"/>
        </div>
        <div class="text-gray-400 text-[14px]">
          <LangText msg-key="4w2e"/>
        </div>
      </div>
      <div class="mt-[24px]">
        <div class="p-[16px] border-[1px] border-gray-200 rounded-[8px]">
          <LangText msg-key="0ibW"/>
          <div class="flex flex-row justify-between text-[14px] text-gray-500">
            <div>TRC20:1U</div>
            <div>ERC20:18U</div>
            <div>OMNI:28U</div>
          </div>
        </div>
        <div class="mt-[8px] p-[16px] border-[1px] border-gray-200 rounded-[8px]">
          <LangText msg-key="mDo9"/>
          <div class="flex flex-row justify-between text-[14px] text-gray-500">
            <div>TRC20:1U</div>
            <div>ERC20:18U</div>
            <div>OMNI:28U</div>
          </div>
        </div>
        <div class="mt-[8px] p-[16px] border-[1px] border-gray-200 rounded-[8px]">
          <LangText msg-key="vMis"/>
          <div class="flex flex-row justify-between text-[14px] text-gray-500">
            <div>TRC20:1U</div>
            <div>ERC20:18U</div>
            <div>OMNI:28U</div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-[24px]">
      <LangText msg-key="QChd"/>
      <div class="text-gray-500 text-[14px] mt-[8px]">
        <LangText msg-key="ElEH"/>
      </div>
    </div>
  </Modal>
</template>
