<script setup >
import {LoadingOutlined} from '@ant-design/icons-vue';

import {Transition} from 'vue';
import {Button} from "ant-design-vue";

const props = defineProps({
  type: {
    type: String,
    default: "primary", // primary | info
  },
  loading: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: "middle", //middle | small
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});


const btnTypeClass = (type) => {
  if (props.disabled) {
    return 'disabled';
  }
  switch (type) {
    case 'primary':
      return type;
    case 'error':
      return type;
    default:
      return 'default';
  }
}

const loadingStyle = (type) => {
  switch (type) {
    case 'primary':
      return {color: 'white', size: '16px'};
    case 'disabled':
      return {color: 'rgba(0, 0, 0, 0.25)', size: '16px'};
    default:
      return {color: 'black', size: '16px'};
  }
}

const sizeClass = () => {
  switch (props.size) {
    case 'small':
      return 'h-[24px] text-[14px] px-[7px] font-normal';
    default:
      return 'h-[32px] text-[16px] py-[4px] px-[15px]';
  }
}

const emit = defineEmits(["click"]);

const onClick = () => {
  if (props.loading || props.disabled) {
    return;
  }
  emit('click');
}

</script>

<template>
  <div
      :class="[
          btnTypeClass(type),
          sizeClass(),
          'inline-flex items-center justify-center cursor-pointer border-[1px] rounded-[6px]',
      ]"
      @click="onClick"
  >
    <Transition name="slide-fade">
      <div
          class="flex items-center justify-center h-[100%] mr-[8px]"
          v-if="loading"
      >
        <LoadingOutlined
            :style="loadingStyle(type)"
        />
      </div>
    </Transition>
    <slot/>

  </div>

</template>

<style lang="scss" scoped>
.primary {
  background-color: $primary;
  color: white;
  border-color: $primary;
}

.primary:hover {
  background-color: $primary-hover;
  color: white;
  border-color: $primary-hover;
}

.primary:hover {
  background-color: $primary-hover;
  color: white;
  border-color: $primary-hover;
}

.default {
  background-color: $default;
  color: theme('colors.gray.900');
  border-color: theme('colors.gray.300');
}

.default:hover {
  background-color: $default-hover;
  color: theme('colors.gray.900');
  border-color: $primary-hover;
}

.disabled {
  cursor: not-allowed;
  background-color: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
}

.disabled:hover {
  cursor: not-allowed;
  background-color: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
}

.error {
  background-color: $error;
  color: white;
  border-color: $error;
}

.error:hover {
  background-color: $error-hover;
  color: white;
  border-color: $error-hover;
}

//展示loading延迟
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
}

.slide-fade-enter-from,
  //隐藏loading延迟
.slide-fade-leave-to {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
</style>
